.main-container-emergency{
    display: grid;
    grid-template-columns: repeat(4, minmax(100px, 1fr));
    grid-template-rows: 110px repeat(5, 150px);
    grid-gap: 20px;
}

/* Background color */
.card-3-emergency, 
.card-4-emergency, 
.card-5-emergency,
.card-6-emergency,
.card-7-emergency
{
    padding-left: 20px;
    padding-right: 20px;
} 

/* Titulo */
.card-1-emergency{
    grid-column: 1 / 3;
    grid-row: 1 / 2;
}

/* Ubicación en tiempo real */
.card-2-emergency{
    grid-column: 3 / 5;
    grid-row: 1 / 5;
}

/* Detalle General */
.card-3-emergency{
    grid-column: 1 / 3;
    grid-row: 2 / 3;
}

/* Ubicación de la Emergencia */
.card-4-emergency{
    grid-column: 1 / 2;
    grid-row: 3 / 4;
}

/* Equipo desplegado */
.card-5-emergency{
    grid-column: 2 / 4;
    grid-row: 3 / 4;
}

/* Observaciones */
.card-6-emergency{
    grid-column: 1 / 3;
    grid-row: 4 / 5;
}

/* Se agrego para que se agregar un scroll para que la informacion no se desacomodara con el resto de tareas*/
.card-6-emergency div{
    overflow-y: scroll;
    height: 150px;
}

/* Pacientes */
.card-7-emergency{
    grid-column: 1 / 5;
    grid-row: 5 / 7;
}

.card-8-emergency{
    grid-column: 1 / 5;
    grid-row: 7 / 10;
}

.card-9-emergency{
    grid-column: 1 / 5;
    grid-row: 10 / 11;
}

.btn-atras{
    grid-column: 4 / 5;
    grid-row: 11 / 12;
}


/* ! MEDIA QUERYS */
/* 1060 px */
@media screen and (max-width: 1400px) {
    .main-container-emergency{
        grid-template-columns: repeat(2, minmax(100px, 1fr));
        grid-template-rows: repeat(6, 161px);
    }

    .card-3-emergency, 
    .card-4-emergency, 
    .card-5-emergency,
    .card-6-emergency,
    .card-7-emergency
    {
        padding: 20px;
    } 

    /* Titulo */
    .card-1-emergency{
        grid-column: 1 / 3;
        grid-row: 1 / 2;
    }

    /* Ubicación en tiempo real */
    .card-2-emergency{
        grid-column: 1 / 3;
        grid-row: 2 / 4;
    }

    /* Detalle General */
    .card-3-emergency{
        grid-column: 1 / 2;
        grid-row: 4 / 5;
    }

    /* Ubicación de la Emergencia */
    .card-4-emergency{
        grid-column: 2 / 3;
        grid-row: 4 / 5;
    }

    /* Equipo desplegado */
    .card-5-emergency{
        grid-column: 1 / 3;
        grid-row: 5 / 6;
    }

    /* Observaciones */
    .card-6-emergency{
        grid-column: 2 / 3;
        grid-row: 5 / 6;
    }

    /* Pacientes */
    .card-7-emergency{
        grid-column: 1 / 3;
        grid-row: 6 / 8;
    }

    .card-8-emergency{
        grid-column: 1 / 3;
        grid-row: 8 / 10;
    }

    .card-9-emergency{
        grid-column: 1 / 3;
        grid-row: 10 / 11;
    }

    .btn-atras{
        grid-column: 2 / 3;
        grid-row: 11 / 12;
    }

}

/* movil */
@media screen and (max-width: 1024px) {
    .main-container-emergency{
        grid-template-columns: repeat(1, minmax(100px, 1fr));
        grid-template-rows: repeat(8, 161px);
    }

    .card-3-emergency, 
    .card-4-emergency, 
    .card-5-emergency,
    .card-6-emergency,
    .card-7-emergency
    {
        padding: 20px;
    } 

    /* Titulo */
    .card-1-emergency{
        grid-column: 1 / 1;
        grid-row: 1 / 2;
    }

    /* Ubicación en tiempo real */
    .card-2-emergency{
        max-height: 300px;
        grid-column: 1 / 1;
        grid-row: 2 / 4;
    }

    /* Detalle General */
    .card-3-emergency{
        grid-column: 1 / 1;
        grid-row: 4 / 5;
    }

    /* Ubicación de la Emergencia */
    .card-4-emergency{
        grid-column: 1 / 1;
        grid-row: 5 / 6;
    }

    /* Equipo desplegado */
    .card-5-emergency{
        grid-column: 1 / 1;
        grid-row: 6 / 7;
    }

    /* Observaciones */
    .card-6-emergency{
        grid-column: 1 / 1;
        grid-row: 7 / 8;
    }

    /* Pacientes */
    .card-7-emergency{
        grid-column: 1 / 1;
        grid-row: 8 / 10;
    }

    /* Pacientes */
    .card-8-emergency{
        grid-column: 1 / 1;
        grid-row: 10 / 12;
    }

    /* QR */
    .card-9-emergency{
        grid-column: 1 / 1;
        grid-row: 12 / 13;
    }

    .btn-atras{
        grid-column: 1 / 1;
        grid-row: 13 / 14;
    }

}